import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MinimalLayout from "./layouts/Minimal";
import MainLayout from "./layouts/Main";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import { Helmet } from "react-helmet";

export const renderRoutes = (routes = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    <Helmet>
                      <title>{route.title}</title>
                    </Helmet>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    path: "/404",
    component: lazy(() => import("./views/Dashboard")),
  },
  {
    exact: true,
    layout: MinimalLayout,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/LoginView")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/Dashboard")),
      },
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("./views/Account")),
        title: "TA|Account",
      },
      {
        exact: true,
        path: "/Websites",
        component: lazy(() => import("./views/Website/WebsiteList")),
        title: "TA|Websites",
      },
      // {
      //   exact: true,
      //   path: "/subscriptions",
      //   component: lazy(() =>
      //     import("./views/Subscriptions/SubscriptionsViews")
      //   ),
      //   title: "TA|Websites",
      // },
      // {
      //   exact: true,
      //   path: "/subscriptions/edit",
      //   component: lazy(() =>
      //     import("./views/Subscriptions/SubscriptionsEdit")
      //   ),
      //   title: "TA|Websites",
      // },
      // {
      //   exact: true,
      //   path: "/offers",
      //   component: lazy(() => import("./views/Offers/OfferViews")),
      //   title: "TA|Websites",
      // },
      // {
      //   exact: true,
      //   path: "/offers/create",
      //   component: lazy(() => import("./views/Offers/OfferCreate")),
      //   title: "TA|Websites",
      // },
      // {
      //   exact: true,
      //   path: "/offers/edit",
      //   component: lazy(() => import("./views/Offers/OfferEdit")),
      //   title: "TA|Offers",
      // },
      {
        exact: true,
        path: "/Websites/create",
        component: lazy(() => import("./views/Website/WebsiteCreate")),
        title: "/Websites/create",
      },
      {
        exact: true,
        path: "/superadmin",
        component: lazy(() => import("./views/SuperAdminList/SuperAdminList")),
        title: "/superadmin",
      },
      // {
      //   exact: true,
      //   path: '/generatewebsite',
      //   component: lazy(() => import('./views/GenerateWebsite/GenerateWebsite')),
      //   title: 'generate websites'
      // },
      {
        exact: true,
        path: "/users/students/create",
        component: lazy(() => import("./views/Website/WebsiteCreate")),
        title: "TA|Students-create",
      },
      {
        exact: true,
        path: "/website/Edit",
        component: lazy(() => import("./views/Website/WebsiteEdit")),
        title: "/website/Edit",
      },
      {
        exact: true,
        path: "/users/students/view/:id",
        component: lazy(() => import("./views/Website/UserShow")),
        title: "TA|Students-view",
      },
      {
        exact: true,
        path: "/contents/divisions",
        component: lazy(() => import("./views/Division/DivisionList")),
        title: "TA|Contents-divisions",
      },
      {
        exact: true,
        path: "/contents/divisions/create",
        component: lazy(() => import("./views/Division/DivisionCreate")),
        title: "TA|Contents-divisions-create",
      },
      {
        exact: true,
        path: "/contents/divisions/edit/:id",
        component: lazy(() => import("./views/Division/DivisionEdit")),
        title: "TA|Contents-divisions-edit",
      },
      {
        exact: true,
        path: "/contents/chapters",
        component: lazy(() => import("./views/Chapter/ChapterList")),
        title: "TA|Contents-chapters",
      },
      {
        exact: true,
        path: "/contents/chapters/create",
        component: lazy(() => import("./views/Chapter/ChapterCreate")),
        title: "TA|Contents-chapters-create",
      },
      {
        exact: true,
        path: "/contents/chapters/edit/:id",
        component: lazy(() => import("./views/Chapter/ChapterEdit")),
        title: "TA|Contents-chapters-edit",
      },
      {
        exact: true,
        path: "/contents/chapters/view/:id",
        component: lazy(() => import("./views/Chapter/ChapterShow")),
        title: "TA|Contents-chapters-view",
      },
      {
        exact: true,
        path: "/dashboard",
        component: () => <Redirect to="/" />,
        title: "TA|Dashboard",
      },
    ],
  },
  {
    path: "*",
    layout: MinimalLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      {
        component: () => <Redirect to="/404" />,
        title: "TA|Not found",
      },
    ],
  },
];

export default routes;
