import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ArchiveIcon from "@material-ui/icons/Archive";
import AppsIcon from "@material-ui/icons/Apps";
import BarChartIcon from "@material-ui/icons/BarChart";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import HttpIcon from "@material-ui/icons/Http";
import HighlightIcon from "@material-ui/icons/Highlight";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SettingsIcon from "@material-ui/icons/Settings";
import DnsIcon from "@material-ui/icons/Dns";
import Looks5Icon from "@material-ui/icons/Looks5";
import { Profile, SidebarNav } from "./components";
import { api_get } from "../../../../utils/Api";
import Loading from "../../../../components/Loading/Loading";
import { useStore } from "../../../../contexts/JWTAuthContext";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import HistoryIcon from "@material-ui/icons/History";
import { SettingsApplications } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "redux/slices/user";
import CardMembershipIcon from "@material-ui/icons/CardMembership";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(9) + 5,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, Click, sidebar, ...rest } = props;
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const classes = useStyles();
  const pages = [
    {
      title: "Websites",
      icon: <HttpIcon />,
      href: "/Websites",
    },
    // {
    //   title: "Offres",
    //   icon: <LocalOfferIcon />,
    //   href: "/offers",
    // },
    // {
    //   title: "Subscriptions",
    //   icon: <CardMembershipIcon />,
    //   href: "/subscriptions",
    // },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    if (user.length === 0) dispatch(fetchUser());
  }, []);
  return (
    <Drawer
      ModalProps={{ onBackdropClick: onClose }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      anchor="left"
      onClose={open}
      open={open}
      variant={variant}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.toolbar}>
          <IconButton onClick={onClose}></IconButton>
        </div>
        <Profile profil={open} user={user} />
        <Divider className={classes.divider} />
        <SidebarNav
          Click={Click}
          sidebar={open}
          className={classes.nav}
          pages={pages}
          user={user}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
